<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="page-wrap">
    <!-- 顶部 -->
    <top-bar-arrow>
      <template slot="title">
        <img class="title-image" src="../../assets/kucun_img_title.png" mode="" />
      </template>
    </top-bar-arrow>
    <div class="main">
      <!-- 待签发 -->
      <div class="panel panel1">
        <div class="title">
          <div class="left">
            <img src="../../assets/kucun_img_dqftitle.png" mode="" />
            <div class="text">{{ qflist.length }}</div>
          </div>
          <div class="right btn-line">
            <div class="btn-normal" @click="gotoWaitSign">详情</div>
          </div>
        </div>
        <div class="context qf-list-wrap">
          <div class="qf-scroll">
            <template v-for="(item, index) in qflist">
              <div class="qf-item" :key="index" @click="changeSelect(index, item)">
                <div class="img-wrap">
                  <img :src="item.small_image_url" mode="" />
                </div>
                <div v-if="isSelectflag" class="choose" :class="{'active': isSelect(index)}"></div>
                <div class="title-wrap">
                  <div class="text">¥{{ parseInt(item.product_market_price)}}</div>
                </div>
              </div>
            </template>
            <template v-if="qflist.length == 0">
              <template v-for="(item, index) in qfEmtyListLength">
                <div class="qf-item" :key="index">
                  <div class="img-wrap">
                    <image src="" mode=""></image>
                  </div>
                  <div class="title-wrap">
                    <div class="text"></div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <!-- 待开启 -->
      <div class="panel panel2">
        <div class="title">
          <div class="left">
            <img src="../../assets/kucun_img_dkqtitle.png" mode="" />
            <div class="text">{{ kqList.length }}</div>
          </div>
        </div>
        <div class="context kq-list-wrap">
          <template v-if="kqList && kqList.length > 0">
            <template v-for="(item, index) in kqList">
              <div class="kq-item" :key="index" @click="gotoOpen(item)">
                <div class="img-wrap">
                  <img src="../../assets/nsmh_icon_bx_big.png" mode="" />
                </div>
                <div class="title-wrap">
                  <div class="text"></div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <my-empty class="empty-wrap">
              <div class="message">选购盲盒加入抽盒潮流吧</div>
            </my-empty>
          </template>
        </div>
      </div>
      <!-- 历史 -->
      <div class="panel panel3" >
        <div class="title">
          <div class="left">
            <img src="../../assets/kucun_img_listitle.png" mode="" />
          </div>
          <div class="right" @click="gotoHistory">
            <div class="text">全部</div>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="context">
          <div :scroll-x="true" class="list-scroll-div">
            <div class="history-list">
              <template v-for="(item, index) in historyList">
                <div class="history-item" :key="index">
                  <div class="image-wrap">
                    <image :src="item.small_image_url" mode=""></image>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="bor"></div>
      </div>
    </div>
    <!-- 详情 -->
    <van-popup class="detail-popup" v-model="show" position="bottom">
      <div class="popup-main">
        <div class="popup-main-content">
          <div class="close-wrap" @click="closePopup">返回</div>
          <div class="title">商品详情</div>
          <div class="goods-item">
            <div class="left">
              <img :src="curProduct.small_image_url" mode="" />
            </div>
            <div class="right">
              <div class="line1">{{ curProduct.name }}</div>
              <div class="line2">
                <div class="text1">价格</div>
                <div class="text2">￥{{ curProduct.market_price }}</div>
              </div>
            </div>
          </div>
          <div class="goods-content" v-if="curProduct.image_url && curProduct.image_url.length > 0">
            <div :scroll-y="true" class="detail-scroll">
              <template v-for="(item, index) in curProduct.image_url">
                <img :src="item" mode="widthFix" :key="index" />
              </template>
            </div>
          </div>
          <div class="btn-line">
            <div class="btn-normal" @click="gotoRepeat">重抽</div>
            <div class="btn-save">
              <div class="btn-content" @click="gotoSign">
                签发
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
  import { getWaitConfirmed, getWaitOpen, getHistory, getDetail } from '../../common/api/shop'
  import { mapGetters, mapMutations } from 'vuex'
  import myEmpty from '../../components/my-empty'
  import topBarArrow from '../../components/top-bar-arrow'
  export default {
    components: {
      myEmpty,
      topBarArrow
    },
    data() {
      return {
        qflist: [],
        qfEmtyListLength: 0,
        kqList: [],
        historyList: [],
        curGoods: {},
        curProduct: {}, // 详情
        isSelectflag: false, // 是否是编辑状态
        qfSelectArr: [],
        dataList: [],
        curIndex: '', // 当前待签发的index
        show: false
      };
    },
    mounted () {
      this.initQF()
      this.initOpen()
      this.initHistory()
    },
    activated () {
      this.initQF()
      this.initOpen()
      this.initHistory()
    },
    methods: {
      ...mapMutations(['UPDATE_GOODLIST','UPDATE_CUR_GOODLIST']),
      gotoWaitSign () {
        this.$router.push({
          name: 'waitSign'
        })
        this.UPDATE_CUR_GOODLIST([])
      },
      closePopup () {
        this.show = false
      },
      // 签发选择
      changeSelect (index, item) {
        this.curGoods = item
        this.show = true
        getDetail({
          data: {
            product_id: item.product_id
          },
          success: res => {
            if (res.code == 200) {
              this.curProduct = res.data

            }
          }
        })
      },
      gotoRepeat () {
        this.$router.push({
          name: 'boxOpen',
          params: {
            curProduct: this.curGoods
          }
        })
      },
      initQF () {
        getWaitConfirmed({
          data: {},
          success: res => {
            if (res.code == 200) {
              this.qflist = res.data.list
              this.qfEmtyListLength = this.qflist.length >=4 ? 0 : (4 - this.qflist.length)
              this.UPDATE_GOODLIST(this.qflist)
            }
          }
        })
      },
      initOpen () {
        getWaitOpen({
          success: res => {
            if (res.code == 200) {
              this.kqList = res.data.list
            }
          }
        })
      },
      initHistory () {
        getHistory({
          success: res => {
            if (res.code == 200) {
              this.historyList = res.data.list
            }
          }
        })
      },
      gotoHistory () {
        this.$router.push({
          name: 'historyOrder'
        })
      },
      gotoOpen(item) {
        this.$router.push({
          name: 'boxOpen',
          params: {
            item: item
          }
        })
      },
      // 签发
      gotoSign () {
        this.show = false
        this.UPDATE_CUR_GOODLIST([this.curGoods])
        this.$router.push({
          name: 'waitSign'
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "./orderList.scss"
</style>

